/////////////////////////////////////////////
// DEFAULT PROPS FOR COMPONENTS
/////////////////////////////////////////////

export const DEFAULT_PROPS = {
  button_transparent: {
    _active: { borderColor: 'transparent' },
    _hover: { borderColor: 'transparent' },
    _focus: { borderColor: 'transparent' },
  },
  button: {
    fontWeight: 'normal',
    bgColor: 'bgSecondary',
    color: 'secondaryColorText',
    fontFamily: 'body',
    padding: '8px 16px',
    height: 'unset',
    borderRadius: '24px',
    boxShadow: 'md',
    size: 'md',
    _active: { borderColor: 'transparent' },
    _hover: { borderColor: 'transparent' },
    _focus: { borderColor: 'transparent' },
  },
  option: {
    style: { color: 'black' },
  },
};

export const TEXT_PROPS = {
  default: {},
  title: {
    fontSize: '34px',
    color: 'mainColorText',
    fontFamily: 'heading',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  sub_title: {
    fontFamily: 'headingAlternative',
    color: 'mainColorText',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '38px',
  },
  body: {
    fontFamily: 'body',
    color: 'mainColorText',
    fontSize: '18px',
    textAlign: 'left',
  },
  photo_tag: {
    fontSize: '14px',
    textAlign: 'right',
    color: 'mainColorText2',
  },
};
